<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t("AppHelpDesk.activeUsers.add") }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppHelpDesk.columns.user')"
                  rules="required"
                >
                  <b-form-group>
                    <h4>{{ $t("AppHelpDesk.columns.user") }}</h4>
                    <v-select
                      v-model="activityData.nombreUsuario"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      label="texto"
                      :options="helpDeskUsers"
                      :reduce="(option) => option.id"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <h5>{{ $t("AppHelpDesk.columns.shiftStart") }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppHelpDesk.columns.startDate')"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-datepicker
                      id="fecha-inicio"
                      v-model="activityData.fechaInicio"
                      today-button
                      close-button
                      :min="min"
                      locale="es"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppHelpDesk.columns.startTime')"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-timepicker
                      id="hora-inicio"
                      v-model="activityData.horaInicio"
                      locale="es"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <h5>{{ $t("AppHelpDesk.columns.shiftEnd") }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppHelpDesk.columns.endDate')"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-datepicker
                      id="fecha-fin"
                      v-model="activityData.fechaFin"
                      today-button
                      close-button
                      :min="min"
                      locale="es"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('AppHelpDesk.columns.endTime')"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-timepicker
                      id="hora-fin"
                      v-model="activityData.horaFin"
                      locale="es"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="float-right mt-2 mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="addingActivity"
                  small
                  class="mr-1"
                />
                {{ $t("Lists.Add") }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BFormTimepicker,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import optionService from '@/services/option.service'
import helpDeskService from '@/services/helpDesk.service'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,

    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const users = ref([])
    const helpDeskUsers = ref([])
    const addingActivity = ref(false)
    const blankActivity = {
      usuarioActivoId: 0,
      nombreUsuario: '',
      fechaInicio: '',
      fechaFin: '',
      horaInicio: '',
      horaFin: '',
    }
    const now = new Date()
    const min = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const activityData = ref(JSON.parse(JSON.stringify(blankActivity)))
    const resetActivityData = () => {
      activityData.value = JSON.parse(JSON.stringify(blankActivity))
    }
    const {
      refFormObserver,
      resetForm,
      getValidationState,
    } = formValidation(resetActivityData)

    const {
      fetchTextOptions,
    } = optionService()

    const {
      createActivityUser,
    } = helpDeskService()

    fetchTextOptions({ nombreDominio: 'MesaAyudaUsuarios' }, data => {
      helpDeskUsers.value = data
    })

    const onSubmit = () => {
      addingActivity.value = true
      activityData.value.fechaInicio = `${activityData.value.fechaInicio}T${activityData.value.horaInicio}`
      activityData.value.fechaFin = `${activityData.value.fechaFin}T${activityData.value.horaFin}`
      createActivityUser(activityData.value)
        .then(() => {
          addingActivity.value = false
          router.push({ name: 'apps-tickets-user-list' })
        })
        .catch(() => {
          const inicio = activityData.value.fechaInicio.split('T')
          const fin = activityData.value.fechaFin.split('T')
          // eslint-disable-next-line prefer-destructuring
          activityData.value.fechaInicio = inicio[0]
          // eslint-disable-next-line prefer-destructuring
          activityData.value.fechaFin = fin[0]
          // eslint-disable-next-line prefer-destructuring
          activityData.value.horaInicio = inicio[1]
          // eslint-disable-next-line prefer-destructuring
          activityData.value.horaFin = fin[1]

          addingActivity.value = false
        })
    }

    return {
      resetActivityData,
      onSubmit,

      min,
      addingActivity,
      activityData,
      users,
      helpDeskUsers,
      refFormObserver,
      resetForm,
      getValidationState,
    }
  },

}
</script>
